/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeHeader from '../components/home-header'
import HomeContent from '../components/home-content'
import ContactForm from '../components/contact-form'

const Home = () => (
  <Layout>
    <SEO/>
    <HomeHeader/>
    <HomeContent/>
    <ContactForm/>
  </Layout>
)

export default Home