/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Container, Flex, Box, Heading, Text } from '@theme-ui/components'
import ServiceDeskSVG from '../svgs/service-desk'
import ServiceCustomersSVG from '../svgs/service-customers'
import MarketingAnalyticsSVG from '../svgs/marketing-analytics'

const HomeContent = () => (
  <>
    {/* Service Desk - Critical Engagement Layer */}

    <Container mt={[ 15, 6, 17 ]} sx={{ position: 'relative', height: ['2xl', 'auto', '3xl'], }}>
      <Flex sx={{ 
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      }}>
        <ServiceCustomersSVG
          sxProp={{
            position: ['static', 'static', 'absolute'],
            maxWidth: ['70%', '70%', '48%', '38%', '42%'],
            mb: [3, 3, 0],
            mr: [7, 7, 0],
            top: ['auto', 'auto', 'auto', 10, 0],
            bottom: ['auto', 'auto', 0, 'auto', 'auto'],
            right: 2,
            zIndex: -1,
          }}
        />
        <Box>
          <Heading as='h2' variant='subHeading'>Critical Engagement Layer</Heading>
          <Text variant='blurb'>
            Continuous innovation with Salesforce provides the connective tissue between 
            your customers and internal business processes.
          </Text>
        </Box>
      </Flex>
    </Container>

    {/* Service Desk - Adoption is the New ROI */}

    <Container mt={ [8, 6, 16] } sx={{ position: 'relative', height: ['3xl', 'auto', '3xl'], }}>
      <Flex sx={{ 
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
      }}>
        <ServiceDeskSVG
          sxProp={{
            position: ['static', 'static', 'absolute'],
            maxWidth: ['70%', '70%', '45%', '39%', '42%'],
            ml: [6, 6, 0],
            mb: [4, 3, 0],
            left: ['auto', 'auto', 6, 2, 6],
            top: ['auto', 'auto', 14, 'auto'],
            zIndex: -1,
          }}
        />
        <Flex mt={[ 0, 0, 0, 12 ]} sx={{
          flexDirection: 'column',
          alignItems: ['flex-start', 'flex-start', 'flex-end', 'flex-start'],
        }}>
          <Heading as='h2' variant='subHeading'>Adoption is the New ROI</Heading>
          <Text variant='blurb' sx={{
            mr: [7, 4, 6],
            maxWidth: ['100%', 'md', 'sm', 'lg'],
          }}>
            Setup Salesforce to automate repeat business processes, integrate new data sources 
            and enable real-time customer engagement.
          </Text>
        </Flex>
      </Flex>
    </Container>

    {/* Marketing Analytics - Data is Beautiful */}

    <Container mt={[ 0, 8, 16 ]} sx={{ position: 'relative', height: ['2xl', 'auto', '3xl'], }}>
    <Flex pt={[ 0, 0, '20rem', 17 ]} sx={{ 
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
        <MarketingAnalyticsSVG
          sxProp={{
            position: ['static', 'static', 'absolute'],
            maxWidth: ['95%', '95%', '70%', '56%', '58%'],
            mb: [4, 3, 0],
            top: 0,
            right: 0,
            zIndex: -1,
          }}
        />
        <Box mt={ 0 }>
          <Heading as='h2' variant='subHeading'>Data is Beautiful</Heading>
          <Text variant='blurb'>
            Build Salesforce around specific workflows where data points are tracked automatically 
            and roll into KPI necessary for critical business intelligence.
          </Text>
        </Box>
      </Flex>
    </Container>
  </>
)

export default HomeContent