/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { useColorMode } from 'theme-ui'
import { Container, Flex, Box, Heading, Text, Button, Link } from '@theme-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import scrollTo from 'gatsby-plugin-smoothscroll';
import AgileSVG from '../svgs/agile'
// import ThemeSwitch from "../svgs/theme-switch"

// const modes = [`light`, `dark`]

const HomeHeader = () => {
  // const [mode, setMode] = useColorMode()

  // const cycleMode = (e) => {
  //   const i = (modes.indexOf(mode) + 1) % modes.length
  //   setMode(modes[i])
  // }
  return (
    <Container sx={{ position: 'relative', height: ['auto', 'auto', '4xl', '3xl'], }}>
      <Flex sx={{ 
        flexDirection: ['column', 'column', 'row'],
        flexWrap: 'wrap',
        alignItems: 'flex-start', 
        justifyContent: 'flex-start',
      }}>
        <Heading as='h1' sx={{ order: 1,
          mt: [2, 4, 0],
        }}>
          Oliver Sumpton Consulting
        </Heading>
        <Box sx={{ order: [2, 2, 3], width: 'full', }}>
          <Text variant='blurb' sx={{ maxWidth: [ '18rem', 'md', 'lg', '30rem' ], }}>
            I help businesses supercharge Salesforce with best practices and innovative solutions.
          </Text>
        </Box>
        <AgileSVG
          sxProp={{
            order: [3, 3, 4],
            position: ['static', 'static', 'absolute'],
            maxWidth: ['full', 'full', '10/12', '65%'],
            top: 'auto',
            bottom: 0,
            right: 0,
            mt: [2, 0],
            zIndex: -1,
          }}
        />
        <Flex sx={{
          display: ['none', 'none', 'flex' ],
          order: [4, 4, 4, 2],
          width: 'auto',
          justifyContent: 'flex-end',
          mt: [12, 7, 7, 0],
          ml: [0, 0, 10, 10],
          fontSize: 2,
        }}>
          <Button sx={{ 
            variant: 'styles.a',
            p: 0,
            mr: 4,
          }}
            aria-label='Send Email to Oliver Sumpton'
            // href='/#contact-form'
            href='#'
            onClick={() => scrollTo('#contact-form')}
          >
            <FontAwesomeIcon icon={faEnvelopeSquare} size='3x'/>
          </Button>
          <Link
            aria-label="Link to Oliver Sumpton's LinkedIn profile"
            // href='#'
            href='https://www.linkedin.com/in/oliversumpton/'
          >
            <FontAwesomeIcon icon={faLinkedin} size='3x'/>
          </Link>
          {/* <button type="button" title="Change color mode" onClick={cycleMode}
              sx={{ variant: `buttons.transparent`, p: 1, borderRadius: `full`, ml: 9, height: 10, display: 'none' }}
            >
            <ThemeSwitch />
          </button> */}
        </Flex>
      </Flex>
    </Container>
  )
}

export default HomeHeader