/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect } from 'react'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import { gsap, Back } from 'gsap'

const MarketingAnalyticsSVG = ({ sxProp }) => {
  let hidden = false
  const padViewBox = 16 * 2
  const [ref, inView] = useInView({ threshold: 0.6 })
  const tl = gsap.timeline({ paused: true })
  const graphs = [
    '#marketing-analytics-graph-front',
    '#marketing-analytics-graph-middle',
    '#marketing-analytics-graph-back',
  ]

  const moveY = 20
  const scaleX = 0.9
  const scaleY = 0.7
  const rotation = 5
  const duration = 1.1
  const stagger = 0.2
  const ease = Back.easeOut.config(1.2)
  const transformOrigin = '50% 70%'

  function animateIn() {
    tl.clear()
    tl.fromTo(graphs, {
      y: moveY,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      autoAlpha: 0,
      transformOrigin: transformOrigin,
    }, {
      y:0, scaleX: 1, scaleY: 1, rotation: 0, autoAlpha: 1,
      duration: duration, 
      stagger: stagger,
      ease: ease,
    })
    tl.play()
  }

  function animateOut() {
    tl.clear()
    tl.fromTo(graphs, {
      y:0, scaleX: 1, scaleY: 1, rotation: 0, autoAlpha: 1,
      transformOrigin: transformOrigin,
    }, {
      y: moveY,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      autoAlpha: 0,
      duration: duration, 
      stagger: stagger,
      ease: ease,
    })
    tl.play()
  }

  useEffect(() => {
    gsap.set(graphs, {autoAlpha:0})
    hidden = true
  }, [])

  useEffect(() => {
    if (inView) {
      animateIn()
      hidden = false
    } else if (!hidden) {
      animateOut()
      hidden = true
    }
  }, [inView])

  return (
    <svg id="marketing-analytics" ref={ref} xmlns="http://www.w3.org/2000/svg" // viewBox="0 0 1000 765.92"
      viewBox={`-${padViewBox} -${padViewBox} ${1000+(padViewBox*2)} ${765.92+(padViewBox*2)}`}
      sx={sxProp}
    >
      <g id="marketing-analytics-legends">
        <g id="marketing-analytics-legend-left">
          <path d="M222.73,498.15c1.34.78,1.35,2.05,0,2.83l-4.06,2.36a5.39,5.39,0,0,1-4.87,0l-85.59-49.42c-1.34-.77-1.36-2,0-2.82l4.06-2.36a5.44,5.44,0,0,1,4.88,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
          <path d="M277.75,508.5c1.35.78,1.35,2,0,2.82l-4.05,2.36a5.42,5.42,0,0,1-4.87,0L146.73,443.17c-1.34-.77-1.36-2,0-2.82l4-2.35a5.38,5.38,0,0,1,4.88,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
          <path d="M441.05,624.2c1.35.78,1.35,2,0,2.82L437,629.38a5.38,5.38,0,0,1-4.88,0L346.56,580c-1.35-.78-1.36-2.05,0-2.83l4.06-2.36a5.41,5.41,0,0,1,4.88,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
        </g>
        <g id="marketing-analytics-legend-middle">
          <path d="M135.24,426.22c6.38,3.69,6.43,9.65.08,13.34s-16.65,3.68-23,0-6.41-9.66-.08-13.34S128.86,422.54,135.24,426.22Z" transform="translate(0.38 -0.43)" fill="#48cdde"/>
          <path d="M353.57,552.27c6.37,3.68,6.41,9.65.07,13.34s-16.65,3.68-23,0-6.42-9.66-.09-13.34S347.18,548.58,353.57,552.27Z" transform="translate(0.38 -0.43)" fill="#fe85a4"/>
          <path d="M496.08,634.55c1.34.77,1.35,2,0,2.82l-4,2.36a5.39,5.39,0,0,1-4.87,0l-122.11-70.5c-1.35-.78-1.36-2.05,0-2.83l4-2.35a5.36,5.36,0,0,1,4.87,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
        </g>
        <g id="marketing-analytics-legend-right">
          <path d="M571.88,678.32c6.39,3.68,6.43,9.65.09,13.34s-16.65,3.68-23,0-6.41-9.65-.08-13.33S565.51,674.64,571.88,678.32Z" transform="translate(0.38 -0.43)" fill="#f7941d"/>
          <path d="M659.38,750.25c1.34.78,1.35,2,0,2.83l-4.06,2.36a5.41,5.41,0,0,1-4.88,0L564.88,706c-1.34-.77-1.36-2,0-2.82l4.06-2.36a5.44,5.44,0,0,1,4.88,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
          <path d="M714.4,760.6c1.35.78,1.35,2,0,2.82l-4,2.35a5.36,5.36,0,0,1-4.87,0l-122.11-70.5c-1.34-.77-1.36-2,0-2.82l4-2.35a5.36,5.36,0,0,1,4.87,0Z" transform="translate(0.38 -0.43)" fill="#b3c3cf"/>
        </g>
      </g>
      <g id="marketing-analytics-graphs">
        <g id="marketing-analytics-graph-back">
          <path d="M807.09,412.27a13.48,13.48,0,0,0,4.49,8.74l-.16,54.85L618.09,364.24l.3-107.7c2.6.75,4.53-.83,4.54-4a17.91,17.91,0,0,1,3.42,1.66C694.51,293.52,722.64,360.65,807.09,412.27Z" transform="translate(0.38 -0.43)" fill="#f7941d" opacity="0.5"/>
          <path d="M423.83,83.11a13.15,13.15,0,0,1,6,10.33,4.63,4.63,0,0,1-1.14,3.35,3.31,3.31,0,0,1-3.4.69l-.44,155.18L615.15,362.55l.31-107.7A13.51,13.51,0,0,1,611,246c0-4.21,2.69-5.74,6-3.83a13.23,13.23,0,0,1,6,10.06c0,3.47-1.94,5.05-4.54,4.3l-.3,107.7L811.42,475.86l.16-54.85a13.48,13.48,0,0,1-4.49-8.74c0-4.3,2.69-5.84,6-3.93a13.15,13.15,0,0,1,6,10.33c-.28,3.42-2.12,4.73-4.54,4l-.15,54.85,173.5,100.17a3.84,3.84,0,0,1,.91-1.75c1.08-1.14,2.86-1.28,4.88-.11a13.15,13.15,0,0,1,6,10.33c0,3.8-2.7,5.33-6,3.43a13.35,13.35,0,0,1-5.76-8.53l-566-326.76.45-158.56a13.28,13.28,0,0,1-4.5-9.25C417.84,82.73,420.53,81.2,423.83,83.11Z" transform="translate(0.38 -0.43)" fill="#f7941d"/>
          <path d="M819,419.32C901.52,468.51,970.31,552.37,988.77,576a3.84,3.84,0,0,0-.91,1.75L814.36,477.55l.15-54.84C816.93,423.4,818.77,422.09,819,419.32Z" transform="translate(0.38 -0.43)" fill="#f7941d" opacity="0.5"/>
          <path d="M611,246a13.51,13.51,0,0,0,4.49,8.84l-.31,107.7L424.82,252.66l.44-155.18a3.31,3.31,0,0,0,3.4-.69c15.17,20.41,66.31,83,158.11,136Q598.42,239.52,611,246Z" transform="translate(0.38 -0.43)" fill="#f7941d" opacity="0.5"/>
        </g>
        <g id="marketing-analytics-graph-middle">
          <g>
            <path d="M708.88,416.19a13.72,13.72,0,0,0,3.92,7.8l-.31,108.16-192.28-111,.59-205.95c2.32.38,4-1.17,4-4.16a8.89,8.89,0,0,1,3.4,1.38C596.39,251.78,624.4,362.14,708.88,416.19Z" transform="translate(0.38 -0.43)" fill="#fe85a4" opacity="0.5"/>
            <path d="M325.83,1.26a13.14,13.14,0,0,1,6,10.33c0,2.27-1,3.71-2.46,4.1a3.64,3.64,0,0,1-1.55,0L327,309.56,516.23,418.84l.58-205.95a13.71,13.71,0,0,1-3.94-8c0-4.57,2.67-6.1,6-4.19a13.24,13.24,0,0,1,5.95,9.82c0,3.5-1.67,5-4,4.67l-.58,206,192.27,111L712.8,424a13.78,13.78,0,0,1-3.92-7.81c0-4.76,2.65-6.29,6-4.38a13.14,13.14,0,0,1,6,10.33c-.66,3.5-2.12,4.48-4,4.16l-.31,108.16,172.1,99.36a5.34,5.34,0,0,1,6.67-.75,13.17,13.17,0,0,1,6,10.34c0,3.79-2.69,5.32-6,3.41a13.23,13.23,0,0,1-5.6-7.83L323,311.83l.84-298.4a13.23,13.23,0,0,1-4-8.75C319.83.88,322.52-.65,325.83,1.26Z" transform="translate(0.38 -0.43)" fill="#fe85a4"/>
          </g>
          <path d="M816.55,555.49c30,41,59.28,67.61,72,78.33l-172.1-99.37.31-108.15a3.2,3.2,0,0,0,3.84-2.64C761.48,452.61,786.87,514.88,816.55,555.49Z" transform="translate(0.38 -0.43)" fill="#fe85a4" opacity="0.5"/>
          <path d="M512.87,204.89a13.71,13.71,0,0,0,3.94,8l-.58,206L327,309.56l.83-293.83a3.64,3.64,0,0,0,1.55,0C342.64,43,394,137.63,488.61,192.27Q500.31,199,512.87,204.89Z" transform="translate(0.38 -0.43)" fill="#fe85a4" opacity="0.5"/>
        </g>
        <g id="marketing-analytics-graph-front">
          <path d="M610.26,483.63c85.52,55.75,154.15,181.18,170.64,213.06a3.19,3.19,0,0,0-1.81,1.95L605.68,598.53,606,487.42C608.37,488,610.14,486.52,610.26,483.63Z" transform="translate(0.38 -0.43)" fill="#48cdde" opacity="0.5"/>
          <path d="M402.16,323a13.42,13.42,0,0,0,4,8.35l-.43,151.7L216.41,373.78l.64-223.52a3.16,3.16,0,0,0,3.8-2.5c19.29,17.29,81.44,75.25,102.86,122.67,9.54,21.13,19.38,31.7,29.59,37.6C368.62,316.87,384.79,315.23,402.16,323Z" transform="translate(0.38 -0.43)" fill="#48cdde" opacity="0.5"/>
          <path d="M215.09,135.78a13.14,13.14,0,0,1,6,10.33,6.41,6.41,0,0,1-.21,1.65,3.16,3.16,0,0,1-3.8,2.5l-.64,223.52L405.69,483.06l.43-151.7a13.42,13.42,0,0,1-4-8.35c0-4.2,2.69-5.73,6-3.82a13.13,13.13,0,0,1,6,10.32c-.31,3.24-1.89,4.52-4,4.16l-.43,151.7L602.23,596.54l.32-111.12a13.53,13.53,0,0,1-4.23-8.45c0-4.36,2.68-5.89,6-4a13.15,13.15,0,0,1,6,10.33c-.13,3.21-1.9,4.64-4.27,4.1l-.32,111.11L779.09,698.64a3.22,3.22,0,0,1,1.81-1.95,4.48,4.48,0,0,1,3.81.61,13.14,13.14,0,0,1,6,10.33c0,3.8-2.7,5.33-6,3.42a13.31,13.31,0,0,1-5.6-7.82L212.41,376.07,213.06,148a13.19,13.19,0,0,1-4-8.75C209.1,135.4,211.79,133.87,215.09,135.78Z" transform="translate(0.38 -0.43)" fill="#48cdde"/>
          <path d="M598.32,477a13.53,13.53,0,0,0,4.23,8.45l-.32,111.11L409.68,485.37l.43-151.7c2.12.36,3.7-.92,4-3.41,28.69,23.8,52.95,94.47,98.26,120.63C556.41,476.34,578.07,468.86,598.32,477Z" transform="translate(0.38 -0.43)" fill="#48cdde" opacity="0.5"/>
        </g>
      </g>
      <g id="marketing-analytics-person">
        <path d="M69.75,349.57c-.54,2.19-20.2,60.44-23.67,84.73a47.48,47.48,0,0,1,11.87-3C60.3,406.07,64.63,374.75,69.75,349.57Z" transform="translate(0.38 -0.43)" fill="none"/>
        <path d="M66.81,86.71c-25,.82-33.69-22.06-30.12-40.8-9,1.13-3.52,24.18,1.39,43.66,9.3-2.38,25.11,2,25.11,2C65.58,91.7,66.48,89.11,66.81,86.71Z" transform="translate(0.38 -0.43)" fill="none"/>
        <path d="M46.08,434.3c-.26,1.84-.44,3.5-.5,4.9A7.11,7.11,0,0,1,43,443.14h0l-2.34,6.68C24.51,456,22.52,447,22.52,447h0a39.64,39.64,0,0,1-1.42,6.85c-1.17,3.09-3.53,18.72,14,16.66,7.81-1.48,11.2-13.57,14.74-15.33,1.36-.68,3.83-1.73,6.56-3.18.31-6.11.84-13.12,1.54-20.65A47.48,47.48,0,0,0,46.08,434.3Z" transform="translate(0.38 -0.43)" fill="#474c69"/>
        <path d="M40.71,449.82l2.34-6.68h0c-5.07,4.4-16.14,6.11-19.87-1.58,0,0-.25,2.54-.65,5.39h0S24.51,456,40.71,449.82Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M82.64,478.63l.7.27Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M92.75,480c-1.08,0-2.07,0-3,0C90.68,480.05,91.67,480.07,92.75,480Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M84.33,479.22a18.85,18.85,0,0,0,1.92.43A16.5,16.5,0,0,1,84.33,479.22Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M58.15,460.13h0l-2.39,20.66c9.14,4.86,22.84-1,23.8-4h0c-4.37-4-2.75-10.71-1.87-15.07h0C72.26,465.38,62.7,465.06,58.15,460.13Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M87,479.75q.9.13,1.92.21C88.21,479.91,87.58,479.84,87,479.75Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M79.55,476.74c-1,3-14.66,8.9-23.8,4,0,0-6.93,8.11-.3,18.87,2.36,3.22,12.54,5.75,26.54.44,6.78-3.1,23.15-2.51,27.27-8.55,2.07-3.54,3.1-12.09-16.51-11.5-1.08,0-2.07,0-3,0l-.84-.05q-1-.07-1.92-.21l-.72-.1a18.85,18.85,0,0,1-1.92-.43c-.35-.1-.67-.21-1-.32l-.7-.27a10.7,10.7,0,0,1-3.08-1.9h0Z" transform="translate(0.38 -0.43)" fill="#474c69"/>
        <path d="M204.06,154.53c-2.36-8.55-4-23-1.33-25.65s-5.82-4.28-11.79.15-4.57,15.18-3.69,28.45c-6.63,4.86-37.81,15.48-38.48,15.48S136,149.38,136,149.38l-.07,0c-9.86,16.33-23,15.36-23,15.36s18.72,34.71,28.45,32.36,52.78-24.48,52.78-24.48C199.78,172.66,206.42,163.08,204.06,154.53Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M22.53,129.08q-1.39.36-2.76.6c-2.58,9.22-7.85,31.34-.36,34.65,5.93,2.63,15.3,2.92,21.52,2.78-.25-10.49-1-21.44-3-29.09C34.72,138.68,26.61,136,22.53,129.08Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M40.42,198.39S21.59,232.66,32.11,265c6.54,18.13,8.31,73.42,8.31,73.42S26.12,390,23.17,441.56c3.73,7.69,14.8,6,19.87,1.58a7.11,7.11,0,0,0,2.54-3.94c.06-1.4.24-3.06.5-4.9,3.47-24.29,23.13-82.54,23.67-84.73-5.12,25.18-9.45,56.5-11.8,81.73-.7,7.53-1.23,14.54-1.54,20.65-.09,1.72-.16,3.37-.22,4.94a9.35,9.35,0,0,0,2,3.24c4.55,4.93,14.11,5.25,19.5,1.53a8.68,8.68,0,0,0,3.6-4.77c3.25-31.54,23-112.63,27.13-127.66s17.84-68.11,1.69-123.54C67.84,226.69,40.42,198.39,40.42,198.39Z" transform="translate(0.38 -0.43)" fill="#2c2649"/>
        <path d="M22.53,129.08C26.61,136,34.72,138.68,38,138c-1.16-4.65-2.29-9.69-3.22-14.72A33.93,33.93,0,0,1,22.53,129.08Z" transform="translate(0.38 -0.43)" fill="#3c4280"/>
        <path d="M135.93,149.42c.38-.62.76-1.22,1.12-1.89-6.85-16.81-12.6-29.19-42.9-44.23-1.1,3.32-28.74,7.08-31-11.72,0,0-15.81-4.39-25.11-2,1.53,6.09,3,11.84,4,16.45,1.5,7.31-1.94,13.17-7.28,17.28.93,5,2.06,10.07,3.22,14.72,1.92,7.65,2.7,18.6,3,29.09.38,16.2-.51,31.28-.51,31.28s27.42,28.3,69.65,7.3c-.66-15,1.77-43.35,1.77-43.35l1.11,2.44S126.07,165.75,135.93,149.42Z" transform="translate(0.38 -0.43)" fill="#6481ce"/>
        <path d="M91.13,48.53c-1,1.92-.18,11.54-.18,11.54s-.11,1.55-2.22,1.66c-.66-3.87-5-7.19-8.51-4.2-6.63,6,2,14.15,2,14.15S79.78,79.2,70.49,86.5c-1.28.15-2.47.17-3.68.21-.33,2.4-1.23,5-3.62,4.87,2.22,18.8,29.86,15,31,11.72-9.73-3.32-6.63-13.93-4.42-18.79,17.69-4.87,14.37-25.44,11.06-36.93,0-11.95-8.19-20.79-8.19-20.79C87.45,31.29,92.16,46.62,91.13,48.53Z" transform="translate(0.38 -0.43)" fill="#e5807e"/>
        <path d="M38,45.88a5.49,5.49,0,0,0-1.29,0c-3.57,18.74,5.11,41.62,30.12,40.8,1.21,0,2.4-.06,3.68-.21,9.29-7.3,11.72-14.82,11.72-14.82s-8.62-8.18-2-14.15c3.54-3,7.85.33,8.51,4.2,2.11-.11,2.22-1.66,2.22-1.66s-.85-9.62.18-11.54S87.45,31.29,92.6,26.79c0,0,2.44-17.91-25.87-11.28a27.58,27.58,0,0,0-22.42,12,22.72,22.72,0,0,1,3,1.55Z" transform="translate(0.38 -0.43)" fill="#474c69"/>
        <path d="M34.76,123.3c5.34-4.11,8.78-10,7.28-17.28-.95-4.61-2.43-10.36-4-16.45C33.17,70.09,27.65,47,36.69,45.91a5.49,5.49,0,0,1,1.29,0l9.29-16.81a22.72,22.72,0,0,0-3-1.55C39.56,25.41,29.4,22.38,16.24,27-2.19,33.5-3.37,69.94,3.41,86.8s15.93,32.79,2.36,40.46c2.52,2.71,8,3.43,14,2.42q1.36-.24,2.76-.6A33.93,33.93,0,0,0,34.76,123.3Z" transform="translate(0.38 -0.43)" fill="#474c69"/>
      </g>
    </svg>
  )
}

export default MarketingAnalyticsSVG