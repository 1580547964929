/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect, useState } from 'react'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import { gsap, Back } from 'gsap'

const ServiceCustomersSVG = ({ sxProp }) => {
  const [isVisible, setVisibility] = useState(true)
  const [ref, inView] = useInView({ threshold: 0.35 })
  const tl = gsap.timeline({ paused: true })
  const padViewBox = 16 * 2
  const cards = [
    '#service-customers-card-front',
    '#service-customers-card-middle',
    '#service-customers-card-back',
  ]

  const moveY = -70
  const scaleX = 1.1
  const scaleY = 0.8
  const rotation = 6
  const duration = 1.1
  const stagger = 0.2
  const ease = Back.easeOut.config(1)

  function animateIn() {
    tl.clear()
    tl.fromTo(cards, {
      y: moveY,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      autoAlpha: 0,
      transformOrigin:"50% 50%",
    }, {
      y:0, scaleX: 1, scaleY: 1, rotation: 0, autoAlpha: 1,
      duration: duration, 
      stagger: stagger,
      ease: ease,
    })
    tl.play()
  }

  function animateOut() {
    tl.clear()
    tl.fromTo(cards, {
      y:0, scaleX: 1, scaleY: 1, rotation: 0, autoAlpha: 1,
      transformOrigin:"50% 50%",
    }, {
      y: moveY,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      autoAlpha: 0,
      duration: duration, 
      stagger: stagger,
      ease: ease,
    })
    tl.play()
  }

  useEffect(() => {
    gsap.set(cards, {autoAlpha:0})
    setVisibility(false)

  }, [])

  useEffect(() => {
    if (inView) {
      animateIn()
      setVisibility(true)
    } else if (isVisible) {
      animateOut()
      setVisibility(false)
    }
  }, [inView])

  return (
    <svg id="service-customers" ref={ref} xmlns="http://www.w3.org/2000/svg" // viewBox="0 0 500 785.68"
      viewBox={`-${padViewBox} -${padViewBox} ${500+(padViewBox*2)} ${785.68+(padViewBox*2)}`}
      sx={sxProp}
    >
      <g id="service-customers-person-right">
        <path d="M467.9,485.92l-2.16,3.46c2.55,8,7.4,16.5,2.64,27.12a28.45,28.45,0,0,1,1.56,6.42s10.45-13.58,9.87-28.78A13.56,13.56,0,0,0,467.9,485.92Z" transform="translate(0.07 -0.16)" fill="#ffb1b1"/>
        <path d="M438,404.61l-.36-6c-1.81,1.29-11.62,2.84-13.17-7,2.58-2.07,4.52-6.71,2.2-8.39-3.09-2.23-6.71-.26-9.17,4.65L416,386.35s1.93-5.55,1.41-8.39-3.61-6.46,2.07-12c1.88-1.83-.77-5.29-4.26-7.35a20.77,20.77,0,0,1-4.16-3.32s-5.13,3.45-6.29,16c-2.2,8.64-5.55,22.07,1,25.81,5.82,3.31,9.32,4.22,11.48,14.57a10.93,10.93,0,0,1,5.81-2.28C432,408.29,437.38,406,438,404.61Z" transform="translate(0.07 -0.16)" fill="#ffb1b1"/>
        <path d="M384.24,781a39.67,39.67,0,0,1-4.91-.6A39.67,39.67,0,0,0,384.24,781Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M403.69,748.93h0s.51,4.59-9.08,6.92S369,757,364.36,766c0,0,1.61,9,13.55,11.36,9.29,1.68,27,.52,30.07,1.16,3.2.07,22.34,5.29,25.69-4.25,0,.53,0,1.05,0,1.54-.13,8.65-14.46,8.13-22.07,6.71-1.31-1.84-4.38-2.26-8.37-2.2,4-.06,7.06.36,8.37,2.2,7.61,1.42,21.94,1.94,22.07-6.71s-1.27-23.64-3.09-26.92h0c-3.69,2.47-9.32,3.31-13.48,3.56S407.35,751.71,403.69,748.93Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M384.24,781a72.94,72.94,0,0,0,7.54,0A72.94,72.94,0,0,1,384.24,781Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M363.91,768.52c.11,3.41,2.66,8.7,12.64,11.25C366.57,777.22,364,771.93,363.91,768.52Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M396.64,780.7c-1.58.1-3.21.2-4.86.28C393.43,780.9,395.06,780.8,396.64,780.7Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M403.23,780.36c-2,0-4.23.18-6.59.34C399,780.54,401.23,780.39,403.23,780.36Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M411.6,782.56c7.61,1.42,21.94,1.94,22.07-6.71,0-.49,0-1,0-1.54-3.35,9.54-22.49,4.32-25.69,4.25-3.09-.64-20.78.52-30.07-1.16C366,775.08,364.36,766,364.36,766a5,5,0,0,0-.22.56,6.35,6.35,0,0,0-.23,1.92c.11,3.41,2.66,8.7,12.64,11.25.87.22,1.8.42,2.78.6a39.67,39.67,0,0,0,4.91.6,72.94,72.94,0,0,0,7.54,0c1.65-.08,3.28-.18,4.86-.28,2.36-.16,4.59-.31,6.59-.34C407.22,780.3,410.29,780.72,411.6,782.56Z" transform="translate(0.07 -0.16)" fill="#785f5f"/>
        <path d="M341.1,482.92s-6.13-7.81-6.87-13.81-.65-10.2-2.13-10-3.42,1.46-3.68,5.55-.33,6.3-1.74,6.94-22.24-3.39-24.14-3.64-6,1.51-4.77,2.8,4.51,2.39,5.54,4,7.69,7.23,16,8.65c3.42.9,14.23,11.23,14.23,11.23C333.55,485.34,341.1,482.92,341.1,482.92Z" transform="translate(0.07 -0.16)" fill="#ffb1b1"/>
        <path d="M438.42,723.66c-.05-.24-.11-.48-.15-.72C438.31,723.18,438.37,723.42,438.42,723.66Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M454.57,721.32c-.22-.25-1.05-4.27-2.22-10.49h0s-8.65-4.12-11.07-2.19c-1.88,1.51-4.09,7.23-3.1,13.7a20.71,20.71,0,0,1-.21-4.47c2.3,17.09,15.9,19.23,19.19,20s8.42,8.71,16.74,9,9.2-5.23,9.2-5.23c.67-3.19,2-7.89-1.71-21.95h0a25.94,25.94,0,0,1-8.68,4.08C461.48,727.07,455.61,722.49,454.57,721.32Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M438.78,725.08l-.18-.64Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M439.27,726.54c-.07-.18-.13-.35-.19-.52C439.14,726.19,439.2,726.36,439.27,726.54Z" transform="translate(0.07 -0.16)" fill="#c27770"/>
        <path d="M473.9,746.88c-8.32-.29-13.45-8.23-16.74-9s-16.89-2.92-19.19-20a20.71,20.71,0,0,0,.21,4.47c0,.2.05.4.09.6s.1.48.15.72.12.52.18.78l.18.64c.09.31.2.62.3.94s.12.34.19.52c.18.48.38,1,.61,1.46,4,8.52,17.08,9.39,19.41,13a6.88,6.88,0,0,1,.58,3A19.74,19.74,0,0,0,476,750.85c6.78-.49,8.23-6,7.07-9.2C483.1,741.65,482.23,747.17,473.9,746.88Z" transform="translate(0.07 -0.16)" fill="#785f5f"/>
        <path d="M436.81,625.4l-.05-.21A1.6,1.6,0,0,1,436.81,625.4Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M436.37,623.5l-.08-.33C436.32,623.28,436.34,623.39,436.37,623.5Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M431.59,604.3v0Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M431.9,605.49l-.09-.35Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M431.39,603.53l0-.14Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M431.26,603l-.05-.17Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M433.89,613.35l0-.08Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M408.37,533c-6.84,1.23-11-.4-13.16-2.79h0s-1.35,90.61,0,101.06,4.46,98.93,4.85,111.13a8.56,8.56,0,0,0,3.64,6.5c3.66,2.78,9.31,3.81,13.39,3.56s9.79-1.09,13.48-3.56a7.9,7.9,0,0,0,3.75-5.54c1-7-2.9-113.44-3.48-120.22s.38-20.32.38-20.32l1.17-40.66,16.45-7.55c1.55,12.78-8.13,43.75-10.07,50.53s-1.56,21.92-1.56,21.92h0c.3,1.34.57,2.57.79,3.63,1.36,6.67,10.12,57.69,14.36,80.11,1.17,6.22,2,10.24,2.22,10.49,1,1.17,6.91,5.75,18.14,2.46a25.94,25.94,0,0,0,8.68-4.08c3.21-2.5,3.69-5,3.77-6.12.13-1.81-12.52-87.18-13.81-94.15-1.16-6.25,2.25-72.6-1.41-96.51a28.45,28.45,0,0,0-1.56-6.42C462.58,529.48,420.57,530.83,408.37,533Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M434.4,615.4l-.06-.26Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M432.58,608.16l-.06-.23Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M433,609.73l0-.11Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M435.88,621.44l-.06-.23Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M434.92,617.5c0-.11-.06-.23-.08-.34C434.86,617.27,434.89,617.39,434.92,617.5Z" transform="translate(0.07 -0.16)" fill="#67a5ec"/>
        <path d="M438.76,605.17c1.94-6.78,11.62-37.75,10.07-50.53l-16.45,7.55-1.17,40.66.05.17.09.37,0,.14c.05.2.12.45.19.73v0c.06.25.14.54.22.84l.09.35c.18.71.39,1.52.62,2.44l.06.23c.12.46.24,1,.37,1.46l0,.11.89,3.54,0,.08.45,1.79.06.26c.14.58.29,1.17.44,1.76,0,.11.05.23.08.34.3,1.24.61,2.48.9,3.71l.06.23c.14.58.28,1.16.41,1.73l.08.33c.13.57.27,1.13.39,1.69l.05.21c.14.58.27,1.14.39,1.69h0S436.83,612,438.76,605.17Z" transform="translate(0.07 -0.16)" fill="#4a8ad2"/>
        <path d="M415.21,358.6c3.49,2.06,6.14,5.52,4.26,7.35-5.68,5.55-2.58,9.17-2.07,12s-1.41,8.39-1.41,8.39l1.54,1.55c2.46-4.91,6.08-6.88,9.17-4.65,2.32,1.68.38,6.32-2.2,8.39,1.55,9.81,11.36,8.26,13.17,7,.77-4.39,11.1-10.07,16.26-15.23s4-11.1,1.29-15c-.26-16.52-20.91-23.49-26.2-25s-13.29-1.16-17.81-3.1c-6.18,6-3.53,11.51-.16,15A20.77,20.77,0,0,0,415.21,358.6Z" transform="translate(0.07 -0.16)" fill="#ba654b"/>
        <path d="M499.84,440.62c-.77-6.39-18.2-25.07-23.91-28.65s-16.19-13.35-36.11-6.34l0,0a7.7,7.7,0,0,1,1.67,4.38c0,1.74-6,7.84-27.29,7.74,0,0-23.43,14.91-25.94,20.33s-16.07,40.07-19.36,56.53c-5.42-1.55-27.78-11.71-27.78-11.71s-7.55,2.42-7.55,11.71l4,4.26s31.55,28.84,42,27.49,15.29-22.07,15.29-22.07-.39,15.87-1.36,19.94a6.8,6.8,0,0,0,1.75,6c2.16,2.39,6.32,4,13.16,2.79,12.2-2.2,54.21-3.55,60-16.53,4.76-10.62-.09-19.16-2.64-27.12a28.87,28.87,0,0,1-1.23-5.21c-1.16-9.48-2.71-26.84,1.81-39.75a41.36,41.36,0,0,1,8.52,7.36l-6.94,34.14a13.56,13.56,0,0,1,11.91,8.22C483.1,488.34,501.35,455.49,499.84,440.62Z" transform="translate(0.07 -0.16)" fill="#ffb1b1"/>
        <path d="M441.47,410a7.7,7.7,0,0,0-1.67-4.38,2.55,2.55,0,0,0-1.77-1h0c-.65,1.35-6,3.68-14.95,4.74a10.93,10.93,0,0,0-5.81,2.28,8.61,8.61,0,0,0-3.09,6.14C435.47,417.87,441.47,411.77,441.47,410Z" transform="translate(0.07 -0.16)" fill="#680208"/>
        <g>
          <path d="M394.82,504.31l-.28,1.09C394.72,504.71,394.82,504.31,394.82,504.31Z" transform="translate(0.07 -0.16)" fill="#8c0001"/>
          <path d="M475.93,412c-5.71-3.59-16.19-13.35-36.11-6.34l0,0a7.7,7.7,0,0,1,1.67,4.38c0,1.74-6,7.84-27.29,7.74,0,0-23.43,14.91-25.94,20.33a489.46,489.46,0,0,0-16.74,46.27c9.15,20.76,23.32,19.94,23.32,19.94s-.39,15.87-1.36,19.94a6.8,6.8,0,0,0,1.75,6c2.16,2.39,6.32,4,13.16,2.79,12.2-2.2,54.21-3.55,60-16.53,4.76-10.62-.09-19.16-2.64-27.12a28.87,28.87,0,0,1-1.23-5.21c-1.16-9.48-2.71-26.84,1.81-39.75a41.36,41.36,0,0,1,8.52,7.36l-.32,1.58c12.06-4.7,19.06-11.79,23.08-17.84C492.55,427.35,480.5,414.83,475.93,412Z" transform="translate(0.07 -0.16)" fill="#8c0001"/>
        </g>
      </g>
      <g id="service-customers-person-left">
        <path d="M223.34,750.83c-.57-16.11-2.43-40.28-4.64-60.66,4.27,19.82,8.91,44.17,10.06,55.47C228.06,748.11,226.13,749.74,223.34,750.83Z" transform="translate(0.07 -0.16)" fill="none"/>
        <path d="M158.86,571.27c4.91-14.94,13.23-30.92,16.26-45.85s6-27,6-27h0c-.76,17.77-4.92,33.28-7.57,50.29a206.91,206.91,0,0,0-2.09,31.86l-12.74-3.69A12.21,12.21,0,0,0,158.86,571.27Z" transform="translate(0.07 -0.16)" fill="none"/>
        <path d="M198.62,760.39c.1.43.23.87.38,1.31C198.85,761.26,198.72,760.82,198.62,760.39Z" transform="translate(0.07 -0.16)" fill="#c96767"/>
        <path d="M199.24,762.33l.18.43Z" transform="translate(0.07 -0.16)" fill="#c96767"/>
        <path d="M249,772.21c4-2.27,3.22-17.78-2.27-24.39-4.34,3.78-14.56,3.4-18-2.18-1.15-11.3-5.79-35.65-10.06-55.47-1.9-17.47-4-32.17-5.89-36.62h0c9.82-2,18.95-6.28,23.18-11.88h0c4.42,10.71,10.71,80.81,10.71,106.15,5.49,6.61,6.24,22.12,2.27,24.39-5.29,2.91-14.18,1.56-20.87.43h0C234.81,773.77,243.7,775.12,249,772.21Z" transform="translate(0.07 -0.16)" fill="#c96767"/>
        <path d="M205.56,757.53c-.76,2.65-2.91,4.35-6.05,5.43l-.09-.2-.18-.43q-.13-.32-.24-.63c-.15-.44-.28-.88-.38-1.31-.79-3.46.37-6.63,6.4-7.33C205.24,754.71,205.43,756.21,205.56,757.53Z" transform="translate(0.07 -0.16)" fill="#3c3e53"/>
        <path d="M228.76,745.64c3.4,5.58,13.62,6,18,2.18,5.49,6.61,6.24,22.12,2.27,24.39-5.29,2.91-14.18,1.56-20.87.43-.48-4.75-2-9.83-4.6-12.94,0-2.54-.06-5.53-.18-8.87C226.13,749.74,228.06,748.11,228.76,745.64Z" transform="translate(0.07 -0.16)" fill="#3c3e53"/>
        <path d="M209.06,785.18c1.31.19,2.65.35,4,.47C211.71,785.53,210.37,785.37,209.06,785.18Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M181.14,780.07a9,9,0,0,1-3.75-3.16,13.47,13.47,0,0,1-2.19-6.51,13.47,13.47,0,0,0,2.19,6.51A9,9,0,0,0,181.14,780.07Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M194.39,783.28c.92.09,1.77.15,2.54.2C196.16,783.43,195.31,783.37,194.39,783.28Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M190,782.7c1,.17,2.05.32,3,.43C192,783,191,782.87,190,782.7Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M181.14,780.07a23.6,23.6,0,0,0,4.18,1.59A23.6,23.6,0,0,1,181.14,780.07Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M212.81,653.55h0c1.84,4.45,4,19.15,5.89,36.62,2.21,20.38,4.07,44.55,4.64,60.66.12,3.34.18,6.33.18,8.87-4.34,3.78-14.56,3.4-18-2.17-.13-1.32-.32-2.82-.54-4.47-3.08-22.78-14.12-74.26-16.76-80.34-2.84-6.53-5.06-22.38-5.06-22.38h0C189.88,655.19,201.77,655.81,212.81,653.55Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M185.32,781.66c1,.29,2,.53,3.07.75C187.35,782.19,186.32,782,185.32,781.66Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M180.39,765.17a19.37,19.37,0,0,1,3.61-.36c6.19-.11,11.61-.51,15.51-1.85,3.14-1.08,5.29-2.78,6.05-5.43,3.4,5.57,13.62,6,18,2.17,2.58,3.11,4.12,8.19,4.6,12.94h0c.55,5.34-.22,10.25-2.33,11.45-3.31,1.82-8,2-12.73,1.56-1.35-.12-2.69-.28-4-.47-4.58-.66-8.75-1.57-11.06-1.65l-1.07,0c-.77-.05-1.62-.11-2.54-.2l-1.42-.15c-1-.11-2-.26-3-.43l-1.56-.29c-1-.22-2.07-.46-3.07-.75a23.6,23.6,0,0,1-4.18-1.59,9,9,0,0,1-3.75-3.16,13.47,13.47,0,0,1-2.19-6.51C175.22,768,176.57,766,180.39,765.17Z" transform="translate(0.07 -0.16)" fill="#474c69"/>
        <path d="M253.22,483.23c1.85,14.43.21,30.86-16.75,31.19h0a141.76,141.76,0,0,1,1.33-16.12C243,494.19,247.34,487.94,253.22,483.23Z" transform="translate(0.07 -0.16)" fill="#c96767"/>
        <polygon points="159.68 494.81 159.68 494.81 159.68 494.81 159.68 494.81" fill="#e37574"/>
        <path d="M181.17,498.38s-3,12.1-6,27-11.35,30.91-16.26,45.85a12.21,12.21,0,0,1-.09,5.57c-1,4.47-3.38,8.9-2.85,11.83.76,4.16-8.41,14.84-10.11,10.68-.76-5.29-4-16.26-3.41-20a26.65,26.65,0,0,1,2.16-6.59,24.83,24.83,0,0,1,2.76-4.76c1.7-2.08,10.87-39.05,12.29-43.87s0-29.12,0-29.12S167.18,499.89,181.17,498.38Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M212.81,653.55c-11,2.26-22.93,1.64-29.61-3.21a14.49,14.49,0,0,1-2.41-2.2c-2.09-7.63-3.74-15.07-5-22.26l3,.89,3.54-43.11-10.79-3.13a206.91,206.91,0,0,1,2.09-31.86c2.65-17,6.81-32.52,7.57-50.29h0c-14,1.51-21.56-3.41-21.56-3.41h0s.38-27.23,3.41-37.06,28.93-20,28.93-20c3.21,2.27,10.15,2.63,16,.75,4.44,14.48,11.35,40.06,10.71,62.4,8.55,3.11,14.26,1.16,19.19-2.72a141.76,141.76,0,0,0-1.33,16.12,46.18,46.18,0,0,0,.67,9.29c15.5,24.21,11,40.47,7.94,55.6a405,405,0,0,0-7.18,58.93,12.85,12.85,0,0,1-1.91,3.43C231.76,647.27,222.63,651.55,212.81,653.55Z" transform="translate(0.07 -0.16)" fill="#fbc561"/>
        <path d="M197.62,401.56c4.35-6.62,8.32-6.43,10.59-4.16s1.13,8.7-6.15,11c.85,5.29-.09,11.72,3.69,23.44.59,1.83,1.33,4.13,2.15,6.81-5.8,1.88-12.74,1.52-16-.75,11-4.92,7.37-14.19,6.05-18.54-17.4-5.1-14.94-23.44-11.73-30.63,0-6.05,5-17.66,5-17.66h0a62.93,62.93,0,0,0-1.62,12C190.81,389.08,198,395.89,197.62,401.56Z" transform="translate(0.07 -0.16)" fill="#e37574"/>
        <path d="M237.8,498.3c-4.93,3.88-10.64,5.83-19.19,2.72.64-22.34-6.27-47.92-10.71-62.4-.82-2.68-1.56-5-2.15-6.81-3.78-11.72-2.84-18.15-3.69-23.44,7.28-2.27,8.42-8.7,6.15-11s-6.24-2.46-10.59,4.16c.38-5.67-6.81-12.48-7.94-18.53a62.93,62.93,0,0,1,1.62-12c5.87-23.8,24.66-14.48,24.66-14.48,50.3,7.94,23.83,55.21,32.52,66.56,15.13,14.37,26.1,37.44,23.45,53.7a27.3,27.3,0,0,0-18.71,6.41C247.34,487.94,243,494.19,237.8,498.3Z" transform="translate(0.07 -0.16)" fill="#3c364a"/>
        <path d="M145.81,599.35c1.7,4.16,10.87-6.52,10.11-10.68-.53-2.93,1.9-7.36,2.85-11.83l12.74,3.69,10.79,3.13-3.54,43.11-3-.89-56.42-16.7,2.27-43.11,23,6.65a26.65,26.65,0,0,0-2.16,6.59C141.84,583.09,145.05,594.06,145.81,599.35Z" transform="translate(0.07 -0.16)" fill="#3f4155"/>
        <path d="M175.19,539.45c2.48-13.55,5.36-26.56,6-41.07-14,1.51-21.56-3.41-21.56-3.41s.38-27.23,3.4-37.06,28.94-20,28.94-20c3.21,2.26,10.15,2.63,16,.75,4.44,14.48,11.35,40.06,10.71,62.4,8.55,3.11,14.26,1.16,19.19-2.72a142,142,0,0,0-1.33,16.12,46.18,46.18,0,0,0,.67,9.29S222.17,551,175.19,539.45Z" transform="translate(0.07 -0.16)" fill="#3b577f"/>
      </g>
      <g id="service-customers-cards">
        <g id="service-customers-card-back">
          <path d="M104.16,134.84c1.77-1,3.21-.18,3.22,1.85l.24,85.8a7,7,0,0,1-3.19,5.53L3.4,286.35c-1.77,1-3.22.2-3.23-1.83l-.24-85.8a7.1,7.1,0,0,1,3.2-5.55Zm-3.4,54.85,0-15.91c0-1.92-1.39-2.71-3.06-1.74L9.82,222.75a6.7,6.7,0,0,0-3,5.26l0,15.91c0,1.93,1.38,2.71,3.06,1.74L97.73,195a6.71,6.71,0,0,0,3-5.26m0,14.76v-1.94c0-1.92-1.38-2.7-3-1.73L9.9,251.49a6.68,6.68,0,0,0-3,5.25v1.94c0,1.92,1.38,2.7,3.06,1.74L97.77,209.7a6.71,6.71,0,0,0,3-5.25m-85.73-10c-4.62,2.67-8.35,9.12-8.34,14.44s3.77,7.44,8.4,4.77,8.34-9.12,8.33-14.42-3.77-7.46-8.39-4.79m27.5,60a7.41,7.41,0,0,0,3.36-5.82c0-2.14-1.52-3-3.38-1.94L10.28,265.28a7.42,7.42,0,0,0-3.37,5.83c0,2.13,1.53,3,3.39,1.93L42.57,254.4m54.9-31.7a7.42,7.42,0,0,0,3.37-5.83c0-2.13-1.53-3-3.39-1.93l-10.31,6a7.47,7.47,0,0,0-3.37,5.83c0,2.13,1.54,3,3.39,1.93l10.31-6" transform="translate(0.07 -0.16)" fill="#efeff0"/>
          <path d="M97.45,214.94c1.86-1.07,3.38-.2,3.39,1.93a7.42,7.42,0,0,1-3.37,5.83l-10.31,6c-1.85,1.07-3.38.2-3.39-1.93a7.47,7.47,0,0,1,3.37-5.83Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          <g>
            <path d="M42.55,246.64c1.86-1.07,3.38-.2,3.38,1.94a7.41,7.41,0,0,1-3.36,5.82L10.3,273c-1.86,1.07-3.38.2-3.39-1.93a7.42,7.42,0,0,1,3.37-5.83Z" transform="translate(0.07 -0.16)" fill="#c2c2c3"/>
            <path d="M97.75,200.78c1.67-1,3-.19,3,1.73v1.94a6.71,6.71,0,0,1-3,5.25L9.93,260.42c-1.68,1-3,.18-3.06-1.74v-1.94a6.68,6.68,0,0,1,3-5.25Z" transform="translate(0.07 -0.16)" fill="#fff"/>
            <path d="M97.66,172c1.67-1,3-.18,3.06,1.74l0,15.91a6.71,6.71,0,0,1-3,5.26L9.89,245.66c-1.68,1-3.05.19-3.06-1.74l0-15.91a6.7,6.7,0,0,1,3-5.26Z" transform="translate(0.07 -0.16)" fill="#fff"/>
            <path d="M15.07,194.45c4.62-2.67,8.37-.53,8.39,4.79S19.74,211,15.13,213.66s-8.38.54-8.4-4.77S10.45,197.12,15.07,194.45Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          </g>
        </g>
        <g id="service-customers-card-middle">
          <g>
            <path d="M351.85,176.35c4.06-2.35,7.4-.44,7.41,4.24a16.28,16.28,0,0,1-7.36,12.76l-22.55,13c-4.06,2.35-7.4.44-7.41-4.23a16.28,16.28,0,0,1,7.36-12.77Z" transform="translate(0.07 -0.16)" fill="#fff"/>
            <path d="M231.72,245.71c4.06-2.35,7.4-.44,7.41,4.23a16.3,16.3,0,0,1-7.36,12.77l-70.61,40.76c-4.06,2.35-7.39.45-7.41-4.23a16.3,16.3,0,0,1,7.36-12.77Z" transform="translate(0.07 -0.16)" fill="#9373c5"/>
            <path d="M352.32,82.49c3.66-2.12,6.66-.41,6.68,3.8l.09,34.83a14.65,14.65,0,0,1-6.63,11.49l-192.2,111c-3.66,2.11-6.66.4-6.68-3.81l-.1-34.83a14.71,14.71,0,0,1,6.64-11.49Z" transform="translate(0.07 -0.16)" fill="#fff"/>
            <ellipse cx="171.66" cy="152.54" rx="25.79" ry="14.94" transform="matrix(0.5, -0.86, 0.86, 0.5, -46.41, 224.16)" fill="#fff"/>
            <path d="M366.52,1.12c3.88-2.23,7-.43,7.05,4l.54,187.74a15.52,15.52,0,0,1-7,12.13l-221,127.62c-3.86,2.23-7,.41-7-4l-.53-187.73a15.43,15.43,0,0,1,7-12.11Zm-7.43,120L359,86.29c0-4.21-3-5.92-6.68-3.8l-192.2,111a14.71,14.71,0,0,0-6.64,11.49l.1,34.83c0,4.21,3,5.92,6.68,3.81l192.2-111a14.65,14.65,0,0,0,6.63-11.49m.1,32.28,0-4.25c0-4.21-3-5.92-6.67-3.81l-192.21,111a14.71,14.71,0,0,0-6.63,11.49l0,4.25c0,4.21,3,5.92,6.67,3.8l192.2-111a14.71,14.71,0,0,0,6.64-11.49M171.6,131.52c-10.11,5.84-18.27,20-18.23,31.59s8.24,16.3,18.35,10.46S190,153.6,190,142s-8.26-16.29-18.36-10.46m60.17,131.19a16.3,16.3,0,0,0,7.36-12.77c0-4.67-3.35-6.58-7.41-4.23l-70.61,40.76a16.3,16.3,0,0,0-7.36,12.77c0,4.68,3.35,6.58,7.41,4.23l70.61-40.76M351.9,193.35a16.28,16.28,0,0,0,7.36-12.76c0-4.68-3.35-6.59-7.41-4.24l-22.55,13a16.28,16.28,0,0,0-7.36,12.77c0,4.67,3.35,6.58,7.41,4.23l22.55-13" transform="translate(0.07 -0.16)" fill="#b8a2ec"/>
            <path d="M359.17,149.15c0-4.21-3-5.92-6.67-3.81l-192.21,111a14.71,14.71,0,0,0-6.63,11.49l0,4.25c0,4.21,3,5.92,6.67,3.8l192.2-111a14.71,14.71,0,0,0,6.64-11.49Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          </g>
        </g>
        <g id="service-customers-card-front">
          <path d="M177.67,341c2.92-1.68,5.31-.32,5.32,3a11.67,11.67,0,0,1-5.29,9.15l-16.15,9.33c-2.92,1.69-5.32.32-5.33-3a11.7,11.7,0,0,1,5.29-9.15Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          <path d="M91.55,390.71c2.92-1.68,5.31-.32,5.32,3a11.66,11.66,0,0,1-5.28,9.15L41,432.11c-2.92,1.68-5.31.32-5.32-3A11.69,11.69,0,0,1,41,419.93Z" transform="translate(0.07 -0.16)" fill="#6eb24f"/>
          <path d="M178.14,318.76c2.63-1.51,4.78-.28,4.79,2.73v3.05a10.55,10.55,0,0,1-4.76,8.23L40.39,412.33c-2.62,1.51-4.77.28-4.78-2.73v-3.05a10.51,10.51,0,0,1,4.75-8.23Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          <ellipse cx="48.5" cy="323.93" rx="18.49" ry="10.71" transform="translate(-255.87 202.94) rotate(-59.84)" fill="#fff"/>
          <path d="M178,273.7c2.63-1.51,4.78-.28,4.79,2.73l.07,25a10.51,10.51,0,0,1-4.75,8.23L40.33,389.18c-2.63,1.51-4.78.28-4.79-2.73l-.07-25a10.51,10.51,0,0,1,4.75-8.23Z" transform="translate(0.07 -0.16)" fill="#fff"/>
          <path d="M188.2,215.37c2.76-1.6,5-.31,5.05,2.88l.38,134.57a11.1,11.1,0,0,1-5,8.69L30.16,453c-2.77,1.6-5,.31-5.05-2.88l-.39-134.56a11.16,11.16,0,0,1,5-8.7Zm-5.33,86-.07-25c0-3-2.16-4.24-4.79-2.73L40.22,353.26a10.51,10.51,0,0,0-4.75,8.23l.07,25c0,3,2.16,4.24,4.79,2.73l137.79-79.56a10.51,10.51,0,0,0,4.75-8.23m.07,23.15v-3.05c0-3-2.17-4.24-4.79-2.73L40.35,398.32a10.51,10.51,0,0,0-4.75,8.23v3.05c0,3,2.16,4.24,4.78,2.73l137.79-79.56a10.55,10.55,0,0,0,4.76-8.23M48.46,308.86c-7.24,4.18-13.1,14.31-13.07,22.64s5.91,11.68,13.15,7.5,13.1-14.32,13.08-22.64-5.91-11.69-13.16-7.5m43.13,94a11.66,11.66,0,0,0,5.28-9.15c0-3.35-2.4-4.71-5.32-3L41,419.93a11.69,11.69,0,0,0-5.29,9.15c0,3.35,2.4,4.71,5.32,3l50.61-29.22m86.11-49.72A11.67,11.67,0,0,0,183,344c0-3.35-2.4-4.71-5.32-3l-16.16,9.33a11.7,11.7,0,0,0-5.29,9.15c0,3.35,2.41,4.72,5.33,3l16.15-9.33" transform="translate(0.07 -0.16)" fill="#c3ef73"/>
        </g>
      </g>
    </svg>
  )
}

export default ServiceCustomersSVG